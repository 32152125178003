/* eslint-disable no-undef */
let API_URL = '';
if(process.env.NODE_ENV == 'development') {
	API_URL = 'https://backend.narfo.co.za/api';
} else {
	API_URL = 'https://backend.narfo.co.za/api';

}

export default API_URL;
